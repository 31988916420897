import Vue from 'vue';
import Vuex from 'vuex';
import pathify from 'vuex-pathify';

Vue.use(Vuex);

function getImportedModules(r) {
  return Object.entries(r).reduce((modules, [path, module]) => {
    modules[
      path
        .split(/[\\/]/)
        .pop()
        .replace(/\.[^.]+$/, '')
    ] = module.default;
    return modules;
  }, {});
}
const stores = import.meta.glob('@/store/*.js', { eager: true });
let mainStores = getImportedModules(stores);

export default new Vuex.Store({
  plugins: [pathify.plugin],
  modules: mainStores,
});
