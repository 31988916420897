import api from '@/services/api';
import Vue from 'vue';
import { make } from 'vuex-pathify';
import { arrayToObject, summarizeArrayOfObjectsKey, saveFileFromBackend } from '@/assets/js/utility';
import { languages } from '@/assets/js/variables';
import config from '@/assets/styles/config';

const state = {
  dataSources: {},
  dataSourcesFeatures: {},
  layers: {},
  originalOffersTree: {},
  offersTree: {},
  offersTreeFilterIds: {},
  offersCount: {},
  offersLayersRanges: {},
  projects: {},
  projectLayersGeojsons: {},
};

const mutations = {
  ...make.mutations(state),
  SET_DATA_SOURCE_FEATURE(state, { dataSourceName, feature } = {}) {
    if (!state.dataSourcesFeatures[dataSourceName]) {
      state.dataSourcesFeatures = { ...state.dataSourcesFeatures, [dataSourceName]: {} };
    }
    Vue.set(
      state.dataSourcesFeatures[dataSourceName],
      feature.id,
      Object.freeze({
        ...feature,
        properties: { ...feature.properties, id: feature.id },
      })
    );
  },
  SET_ORIGINAL_OFFERS_TREE(state, { lng, value }) {
    Vue.set(state.originalOffersTree, lng, Object.freeze(value));
    Vue.set(state.offersTree, lng, Object.freeze(value));
  },
  SET_DATA_SOURCES(state, dataSources = {}) {
    Vue.set(state, 'dataSources', Object.freeze(dataSources));
  },
  SET_LAYERS(state, layers = {}) {
    Vue.set(state, 'layers', Object.freeze(layers));
  },
  SET_PROJECTS(state, projects = []) {
    Vue.set(state, 'projects', Object.freeze(projects));
  },
  SET_PROJECT_LAYER_GEOJSON(state, { id, geojson }) {
    Vue.set(state.projectLayersGeojsons, id, Object.freeze(geojson));
  },
  SET_LNG_OFFERS(state, offers) {
    Object.keys(offers).forEach(lng => {
      Vue.set(state.lngOffers, lng, Object.freeze(offers[lng]));
    });
  },
};

const actions = {
  async getDataSources({ commit }) {
    const r = await api.get('public/datasources/metadata');
    commit(
      'SET_DATA_SOURCES',
      arrayToObject(
        (r.data.data || [])
          .filter(dataSource => dataSource.scope === 'core')
          .map(dataSource => {
            return {
              name: dataSource.name,
              verboseName: dataSource.verbose_name,
              geometryType: dataSource.geometry_type,
              attributes: dataSource.attributes_schema.attributes,
              descriptionAttributeName: dataSource.attributes_schema.desc_attribute_name,
            };
          }),
        'name'
      )
    );
  },
  async getDataSourceFeatures({ commit }, dataSourceName) {
    const r = await api.get(`public/datasources/${dataSourceName}/geojson`);
    return r.data.data;
  },
  async getDataSourceFeature({ commit }, { dataSourceName, featureId } = {}) {
    const r = await api.get(`public/datasources/${dataSourceName}/feature/${featureId}/geojson`);
    commit('SET_DATA_SOURCE_FEATURE', { dataSourceName, feature: r.data.data });
  },
  async getLayers({ commit }) {
    const r = await api.get('public/layers/metadata');
    commit(
      'SET_LAYERS',
      arrayToObject(
        r.data.data
          .filter(layer => layer.layer_scope === 'core')
          .map(layer => {
            return {
              id: layer.id,
              name: layer.name,
              dataSourceName: layer.data_source_name,
              attributes: layer.form_schema?.elements,
              imageAttributes: layer.image_attributes,
              imageAttribute: layer.image_attributes?.[0],
            };
          }),
        'id'
      )
    );
  },
  async getLayerFeatures({ commit }, { id } = {}) {
    const r = await api.get(`public/layers/${id}/geojson`);
    r.data.data?.features?.features.forEach(feature => {
      commit('SET_DATA_SOURCE_FEATURE', {
        dataSourceName: state.layers[id]?.dataSourceName,
        feature: { ...feature, crs: r.data.data.features.crs },
      });
    });
    commit('SET_PROJECT_LAYER_GEOJSON', { id, geojson: r.data.data?.features });
    return r.data.data?.features;
  },
  async getProjects({ commit }, projects = {}) {
    const r = await api.get('public/projects');
    commit(
      'SET_PROJECTS',
      Object.keys(projects).reduce((total, current) => {
        const currentProject = (r.data.data || []).find(project => project.g_id === projects[current]);
        if (!currentProject) return total;
        total[current] = {
          id: currentProject.g_id,
          layers: currentProject.layers,
          name: currentProject.name,
        };
        return total;
      }, {})
    );
  },
  async getAttachments(store, { dataSourceName, featureId }) {
    return (await api.get(`attachments/${dataSourceName}/${featureId}`)).data.data;
  },

  async getOffersTree(
    { commit, dispatch, rootState },
    { params = { geometry: false, properties: false }, layersIds = [] } = {}
  ) {
    const layersPromises = layersIds.map(layerId => {
      return dispatch('getLayerFeatures', { id: layerId });
    });
    await Promise.all(layersPromises);
    const treesPromises = languages.map(lng => {
      return api.get(`offerts/tree/${lng}`, { params });
    });
    const r = await Promise.all(treesPromises);
    const offersCount = languages.reduce((total, lng) => {
      total[lng] = {
        voivodeship: {},
        district: {},
        commune: {},
      };
      return total;
    }, {});
    const offersLayersCount = languages.reduce((total, lng) => {
      total[lng] = {};
      return total;
    }, {});
    languages.forEach((lng, idx) => {
      const value = r[idx].data.data.map(layer => {
        offersLayersCount[lng][layer.id] = {
          voivodeship: {},
          district: {},
          commune: {},
        };
        let isShallow = true;
        const isTerrain = Object.values(rootState.admin.settings['TERRAIN_LAYERS_SETTING']?.value?.layers).includes(
          layer.id
        );
        const voivodeships = layer.children.map(voivodeship => {
          if (voivodeship.children && isTerrain) isShallow = false;
          else layer.hasExpandableChildren = true;
          const districts = voivodeship.children?.map(district => {
            const communes = district.children?.map(commune => {
              const offers = commune.children;
              commune.hasExpandableChildren = true;
              const offersArea = summarizeArrayOfObjectsKey(offers, 'area');
              offersCount[lng]['commune'][commune.id] = (offersCount[lng]['commune'][commune.id] || 0) + offers.length;
              offersLayersCount[lng][layer.id]['commune'][commune.id] =
                (offersLayersCount[lng][layer.id]['commune'][commune.id] || 0) + offers.length;
              return {
                ...commune,
                type: 'commune',
                children: commune.children.sort((a, b) => a.name.localeCompare(b.name, 'pl')),
                area: offersArea,
                count: offers.length,
              };
            });
            const communesOffersArea = summarizeArrayOfObjectsKey(communes, 'area');
            const communesOffersCount = summarizeArrayOfObjectsKey(communes, 'count');
            offersCount[lng]['district'][district.id] =
              (offersCount[lng]['district'][district.id] || 0) + communesOffersCount;
            offersLayersCount[lng][layer.id]['district'][district.id] =
              (offersLayersCount[lng][layer.id]['district'][district.id] || 0) + communesOffersCount;
            return {
              ...district,
              type: 'district',
              children: communes.sort((a, b) => a.name.localeCompare(b.name, 'pl')),
              area: communesOffersArea,
              count: communesOffersCount,
            };
          });
          const districtsOffersArea = summarizeArrayOfObjectsKey(districts ?? [], 'area');
          const districtsOffersCount = summarizeArrayOfObjectsKey(districts ?? [], 'count');
          offersCount[lng]['voivodeship'][voivodeship.id] =
            (offersCount[lng]['voivodeship'][voivodeship.id] || 0) + districtsOffersCount;
          offersLayersCount[lng][layer.id]['voivodeship'][voivodeship.id] =
            (offersLayersCount[lng][layer.id]['voivodeship'][voivodeship.id] || 0) + districtsOffersCount;
          return {
            ...voivodeship,
            type: 'voivodeship',
            children: districts.sort((a, b) => a.name.localeCompare(b.name, 'pl')),
            area: districtsOffersArea,
            count: districtsOffersCount,
          };
        });
        const typesOffersArea = summarizeArrayOfObjectsKey(voivodeships, 'area');
        const typesOffersCount = isShallow ? voivodeships.length : summarizeArrayOfObjectsKey(voivodeships, 'count');
        return {
          ...layer,
          children: voivodeships.sort((a, b) => {
            if (a.code === '24') return -1;
            if (b.code === '24') return 1;
            return a.name.localeCompare(b.name, 'pl');
          }),
          area: typesOffersArea,
          count: typesOffersCount,
        };
      });
      commit('SET_ORIGINAL_OFFERS_TREE', { lng, value });
    });
    const offersLayersRanges = languages.reduce((total, lng) => {
      total[lng] = {};
      return total;
    }, {});
    Object.keys(offersLayersCount).forEach(lng => {
      Object.keys(offersLayersCount[lng]).forEach(layerId => {
        offersLayersRanges[lng][layerId] = {
          voivodeship: {},
          district: {},
          commune: {},
        };
        Object.keys(offersLayersCount[lng][layerId]).forEach(type => {
          const values = Object.values(offersLayersCount[lng][layerId][type]);
          const maxValue = Math.max(...values);
          const quarter = parseFloat(((0 + maxValue) / 4).toFixed(2));
          offersLayersRanges[lng][layerId][type] = [
            {
              minValue: 0,
              maxValue: quarter,
              color: config.colors.cartogram.min,
            },
            {
              minValue: quarter,
              maxValue: quarter * 2,
              color: config.colors.cartogram[1],
            },
            {
              minValue: quarter * 2,
              maxValue: quarter * 3,
              color: config.colors.cartogram[2],
            },
            {
              minValue: quarter * 3,
              maxValue: maxValue,
              color: config.colors.cartogram.max,
            },
          ];
        });
      });
    });
    commit('SET_OFFERS_LAYERS_RANGES', offersLayersRanges);
  },
  async generateFormPrintKsse(store, { featureId, payload, fileName }) {
    const r = await api.post(`form_print/pdf/${featureId}`, payload, { responseType: 'arraybuffer' });
    saveFileFromBackend(r.data, r.headers, fileName);
  },
  async generateFormPrintScl(store, { featureId, sclType, payload, fileName }) {
    const r = await api.post(`form_print/scl/${sclType}/${featureId}`, payload, { responseType: 'arraybuffer' });
    saveFileFromBackend(r.data, r.headers, fileName);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
