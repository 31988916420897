import Vue from 'vue';
import VueI18n from 'vue-i18n';
import pl from '@/locales/pl.json';
import en from '@/locales/en.json';

Vue.use(VueI18n);

const pluralizationRules = {
  en: choice => {
    return choice === 0 ? 0 : choice === 1 ? 1 : 2;
  },
  pl: choice => {
    return choice === 0
      ? 0
      : choice == 1
      ? 1
      : choice % 10 >= 2 && choice % 10 <= 4 && (choice % 100 < 10 || choice % 100 >= 20)
      ? 2
      : 3;
  },
};

const dateTimeFormat = {
  short: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  },
  long: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    weekday: 'short',
    hour: 'numeric',
    minute: 'numeric',
  },
};
const dateTimeFormats = {
  en: dateTimeFormat,
  pl: dateTimeFormat,
};

export default new VueI18n({
  locale: 'pl',
  fallbackLocale: 'pl',
  messages: {
    pl,
    en,
  },
  pluralizationRules,
  dateTimeFormats,
});
