import { Point, LineString, Polygon, MultiPolygon } from 'ol/geom';
import { getCenter } from 'ol/extent';

const getFlatGroupsLayers = (elements = []) => {
  const groups = [];
  const layers = [];
  for (const element of elements) {
    if (element.layers) {
      groups.push(element);
      for (const layer of element.layers) {
        layers.push(layer);
      }
    } else {
      layers.push(element);
    }
  }
  return { groups, layers };
};

const getGeometryCentroid = geometry => {
  if (geometry instanceof Point) {
    return geometry.getCoordinates();
  } else if (geometry instanceof LineString) {
    return geometry.getCoordinateAt(0.5);
  } else if (geometry instanceof Polygon) {
    return geometry.getInteriorPoint().getCoordinates().splice(0, 2);
  } else if (geometry instanceof MultiPolygon) {
    return getCenter(geometry.getExtent());
  }
};

export { getFlatGroupsLayers, getGeometryCentroid };
