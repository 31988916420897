export default {
  colors: {
    cartogram: {
      min: '#E0F1FF',
      1: '#99D1FF',
      2: '#3B92E3',
      max: '#1A6BB7',
    },
    primary: {
      DEFAULT: '#194B6C',
      darken: '#0A3D5E',
      lighten: '#F4F7F9',
    },
    feature_highlight: '#98001E',
    secondary: '#e75113',
    main_border: '#EBEBEB',
    success: '#188038',
    edit: '#ff8800',
    error: '#bf360c',
  },
  borderRadius: {
    primary: '20px',
    secondary: '12px',
    tertiary: '4px',
  },
  spacing: {
    map_padding: '20px',
  },
  opacity: {
    2: '.02',
  },
};
