import Vue from 'vue';

import router from '@/router';
import store from '@/store';
import { languages } from '@/assets/js/variables';

import dayjs from '@/plugins/dayjs';
import i18n from '@/plugins/i18n';
import vuetify from '@/plugins/vuetify';
import utility from '@/plugins/utility';
import variables from '@/plugins/variables';
import 'ol/ol.css';
import '@/assets/styles/tailwind.css';

import App from '@/App.vue';

const languagesModules = import.meta.glob('@/locales/*.json', { eager: true });
Object.entries(languagesModules).forEach(([path, lang]) => {
  const langCode = path.split('/').pop().replace('.json', '');
  vuetify.preset.lang.locales[langCode] = lang.default;
});
Vue.config.productionTip = false;

Vue.use(dayjs);
Vue.use(utility);
Vue.use(variables);

Vue.config.productionTip = false;
Vue.config.devtools = true;

new Vue({
  name: 'mainJs',
  el: '#app',
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
});
