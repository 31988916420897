import axios from 'axios';

import router from '@/router';
import store from '@/store';
import { getErrorObject } from '@/assets/js/apiUtility';

const instance = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL,
  timeout: 0,
  headers: {},
});

instance.interceptors.request.use(config => {
  const { data, noWrap } = config;
  if (!noWrap) {
    if (data) {
      config.data = { data };
    }
  }
  const { token } = store.state.authentication;
  if (token) {
    config.headers['X-Access-Token'] = token;
  }
  return config;
});

instance.interceptors.response.use(
  config => {
    return config;
  },
  error => {
    const errorObject = getErrorObject(error);
    if (errorObject.isShowSnackbar) {
      store.set('snackbar/PUSH_ERROR!', errorObject);
    }
    if (errorObject.isLogout) {
      router.push({ name: 'login', query: { redirect: router.currentRoute.fullPath } });
    }
    throw new Error(errorObject.message);
  }
);

export default instance;
