<template>
  <v-app id="app" class="fill-height">
    <v-main>
      <template v-if="isFetched">
        <router-view />
      </template>
      <AppLoading v-else :migration-in-progress="isDataLoading" />
      <TheSnackbar />
    </v-main>
  </v-app>
</template>

<script>
import TheSnackbar from '@/components/shared/TheSnackbar';
import AppLoading from '@/components/shared/AppLoading';

import { call } from 'vuex-pathify';

export default {
  name: 'App',
  components: { TheSnackbar, AppLoading },
  data: () => ({
    isFetched: false,
    isDataLoading: false,
  }),
  computed: {
    currentLng() {
      return this.$i18n.locale;
    },
  },
  watch: {
    currentLng(nV) {
      if (!nV) return;
      localStorage.setItem('lng', nV);
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    getSettings: call('admin/getSettings'),
    async init() {
      document.title = this.$_config.appName;
      const lng =
        this.$_config.languages.find(language => language === localStorage.getItem('lng')) ||
        this.$_config.languages.find(language => window.navigator.language.startsWith(language)) ||
        this.$_config.defaultLanguage;
      this.$i18n.locale = lng;
      this.$vuetify.lang.current = lng;
      this.$dayjs.locale(lng);
      const r = await this.getSettings();
      if (r.status === 201) {
        this.isDataLoading = true;
        this.isFetched = false;
        return;
      }
      this.isFetched = true;
    },
  },
};
</script>

<style lang="postcss">
html,
body {
  @apply tw-overflow-y-hidden;
}
</style>
