import Vue from 'vue';
import VueRouter from 'vue-router';

import { beforeAdminRouteEnter } from '@/assets/js/routerUtility';

Vue.use(VueRouter);

const routes = [
  {
    path: '',
    component: () => import('@/views/MapView'),
    children: [
      {
        path: '/:lid?',
        name: 'main',
        components: {
          sidebar: () => import('@/components/sidebar/TheSidebarMain'),
        },
      },
      {
        path: '/offer/:lid/:fid',
        name: 'offer',
        components: {
          sidebar: () => import('@/components/sidebar/TheSidebarItem'),
        },
      },
    ],
  },
  {
    path: '/admin/login',
    name: 'login',
    component: () => import('@/views/LoginView'),
  },
  {
    path: '/admin/settings',
    name: 'admin',
    component: () => import('@/views/AdminView'),
    beforeEnter: (to, from, next) => {
      beforeAdminRouteEnter(to, from, next, () => next());
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path === '/admin') {
    next({ name: 'admin' });
  } else {
    next();
  }
});

export default router;
