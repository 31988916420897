import store from '@/store';

const beforeAdminRouteEnter = async (to, from, next, callback) => {
  const isTokenValid = await store.dispatch('authentication/checkToken');
  if (isTokenValid) {
    callback();
  } else {
    store.set('authentication/SET_TOKEN!', null);
    next({
      name: 'login',
    });
  }
};

export { beforeAdminRouteEnter };
