<template>
  <transition-group name="snackbar" tag="div">
    <v-snackbar
      v-for="(snackbar, index) in latestSnackbars"
      :key="snackbar.id"
      :timeout="snackbar.timeout ?? -1"
      :color="snackbar.color"
      :elevation="2"
      :value="true"
      :style="{ bottom: `${(index + 1) * 55}px` }"
      @input="onInput($event, index)"
    >
      <span>
        {{ $i18n.te(`errors.${snackbar.message}`) ? $i18n.t(`errors.${snackbar.message}`) : snackbar.message }}
      </span>
      <template #action="{ attrs }">
        <v-btn v-bind="attrs" :icon="snackbar.buttonType === 'icon'" class="mr-1" text @click="popSnackbar(index)">
          <v-icon v-if="snackbar.buttonType === 'icon'">mdi-close</v-icon>
          <span v-else> {{ $i18n.t('dialog.disagreeClose') }} </span>
        </v-btn>
      </template>
    </v-snackbar>
  </transition-group>
</template>

<script>
import { get } from 'vuex-pathify';

const MAX_SNACKBARS_AMOUNT = 3;

export default {
  name: 'TheSnackbar',
  data: () => ({
    snackbars: [],
  }),
  computed: {
    messages: get('snackbar/messages'),
    successfulls: get('snackbar/successfulls'),
    errors: get('snackbar/errors'),
    latestSnackbars() {
      return this.snackbars.slice(0, MAX_SNACKBARS_AMOUNT);
    },
  },
  watch: {
    errors(nV) {
      this.pushSnackbar({ ...this.getLast(nV), color: this.$_colors.error });
    },
    successfulls(nV) {
      this.pushSnackbar({ ...this.getLast(nV), color: this.$_colors.success });
    },
    messages(nV) {
      this.pushSnackbar(this.getLast(nV));
    },
  },
  methods: {
    getLast(nV) {
      return nV[nV.length - 1];
    },
    pushSnackbar({
      message,
      buttonType = 'icon',
      color = this.$_colors.primary.DEFAULT,
      timeout = 5000,
      customActionButton,
    }) {
      this.snackbars.push({ message, buttonType, color, timeout, id: Date.now(), customActionButton });
    },
    popSnackbar(index) {
      this.snackbars.splice(index, 1);
    },
    onInput(isVisible, index) {
      if (isVisible) {
        return;
      }
      this.popSnackbar(index);
    },
  },
};
</script>

<style lang="scss" scoped>
.snackbar-enter-active,
.snackbar-leave-active {
  transition: all 1s;
}
.snackbar-enter,
.snackbar-leave-to {
  opacity: 0;
  transform: translateY(100px);
}
</style>
