const mapDataCoordinateSystem = {
  center: [2067558.705654182, 6491593.22830775],
  epsg: 'EPSG:3857',
  srid: 3857,
  verboseName: 'Google Mercator',
};

const mapDefaultZoom = 9;
const mapDefaultMinZoom = 0;
const mapDefaultMaxZoom = 28;

const appName = 'Katowicka Specjalna Strefa Ekonomiczna';
const contactData = {
  name: 'Jan Kowalski',
  phone: '+48 341 520 633',
  mail: 'jkowalski@ksse.com.pl',
};
const defaultLanguage = 'pl';
const languages = ['pl', 'en'];

const additionalEpsgs = {
  'EPSG:2180': {
    text: 'EPSG:2180 (PL-1992)',
    def: '+proj=tmerc +lat_0=0 +lon_0=19 +k=0.9993 +x_0=500000 +y_0=-5300000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +axis=neu',
    srid: 2180,
  },
  'EPSG:4258': {
    srid: 4258,
    text: 'EPSG:4258 (ETRS89)',
    def: '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs +axis=neu',
  },
  'EPSG:2176': {
    srid: 2176,
    text: 'EPSG:2176 (PL-2000 V)',
    def: '+proj=tmerc +lat_0=0 +lon_0=15 +k=0.999923 +x_0=5500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +axis=neu',
  },
  'EPSG:2177': {
    srid: 2177,
    text: 'EPSG:2177 (PL-2000 VI)',
    def: '+proj=tmerc +lat_0=0 +lon_0=18 +k=0.999923 +x_0=6500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +axis=neu',
  },
  'EPSG:2178': {
    srid: 2178,
    text: 'EPSG:2178 (PL-2000 VII)',
    def: '+proj=tmerc +lat_0=0 +lon_0=21 +k=0.999923 +x_0=7500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +axis=neu',
  },
  'EPSG:2179': {
    srid: 2179,
    text: 'EPSG:2179 (PL-2000 VIII)',
    def: '+proj=tmerc +lat_0=0 +lon_0=24 +k=0.999923 +x_0=8500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +axis=neu',
  },
  'EPSG:3120': {
    srid: 3120,
    text: 'EPSG:3120 (PL-1965 I)',
    def: '+proj=sterea +lat_0=50.625 +lon_0=21.0833333333333 +k=0.9998 +x_0=4637000 +y_0=5467000 +ellps=krass +towgs84=33.4,-146.6,-76.3,-0.359,-0.053,0.844,-0.84 +units=m +no_defs +axis=neu',
  },
  'EPSG:2172': {
    srid: 2172,
    text: 'EPSG:2172 (PL-1965 II)',
    def: '+proj=sterea +lat_0=53.0019444444444 +lon_0=21.5027777777778 +k=0.9998 +x_0=4603000 +y_0=5806000 +ellps=krass +towgs84=33.4,-146.6,-76.3,-0.359,-0.053,0.844,-0.84 +units=m +no_defs +axis=neu',
  },
  'EPSG:2173': {
    srid: 2173,
    text: 'EPSG:2173 (PL-1965 III)',
    def: '+proj=sterea +lat_0=53.5833333333333 +lon_0=17.0083333333333 +k=0.9998 +x_0=3501000 +y_0=5999000 +ellps=krass +towgs84=33.4,-146.6,-76.3,-0.359,-0.053,0.844,-0.84 +units=m +no_defs +axis=neu',
  },
  'EPSG:2174': {
    srid: 2174,
    text: 'EPSG:2174 (PL-1965 IV)',
    def: '+proj=sterea +lat_0=51.6708333333333 +lon_0=16.6722222222222 +k=0.9998 +x_0=3703000 +y_0=5627000 +ellps=krass +towgs84=33.4,-146.6,-76.3,-0.359,-0.053,0.844,-0.84 +units=m +no_defs +axis=neu',
  },
  'EPSG:2175': {
    srid: 2175,
    text: 'EPSG:2175 (PL-1965 V)',
    def: '+proj=tmerc +lat_0=0 +lon_0=18.9583333333333 +k=0.999983 +x_0=237000 +y_0=-4700000 +ellps=krass +towgs84=33.4,-146.6,-76.3,-0.359,-0.053,0.844,-0.84 +units=m +no_defs +axis=neu',
  },
  'EPSG:3333': {
    srid: 3333,
    text: 'EPSG:3333 (PL-1942 6° III)',
    def: '+proj=tmerc +lat_0=0 +lon_0=15 +k=1 +x_0=3500000 +y_0=0 +ellps=krass +units=m +no_defs +axis=neu',
  },
  'EPSG:3334': {
    srid: 3334,
    text: 'EPSG:3334 (PL-1942 6° IV)',
    def: '+proj=tmerc +lat_0=0 +lon_0=21 +k=1 +x_0=4500000 +y_0=0 +ellps=krass +units=m +no_defs +axis=neu',
  },
  'EPSG:3335': {
    srid: 3335,
    text: 'EPSG:3335 (PL-1942 6° V)',
    def: '+proj=tmerc +lat_0=0 +lon_0=27 +k=1 +x_0=5500000 +y_0=0 +ellps=krass +units=m +no_defs+axis=neu',
  },
  'EPSG:3329': {
    srid: 3329,
    text: 'EPSG:3329 (PL-1942 3° V)',
    def: '+proj=tmerc +lat_0=0 +lon_0=15 +k=1 +x_0=5500000 +y_0=0 +ellps=krass +units=m +no_defs +axis=neu',
  },
  'EPSG:3330': {
    srid: 3330,
    text: 'EPSG:3330 (PL-1942 3° VI)',
    def: '+proj=tmerc +lat_0=0 +lon_0=18 +k=1 +x_0=6500000 +y_0=0 +ellps=krass +units=m +no_defs +axis=neu',
  },
  'EPSG:3331': {
    srid: 3331,
    text: 'EPSG:3331 (PL-1942 3° VII)',
    def: '+proj=tmerc +lat_0=0 +lon_0=21 +k=1 +x_0=7500000 +y_0=0 +ellps=krass +units=m +no_defs +axis=neu',
  },
  'EPSG:3332': {
    srid: 3332,
    text: 'EPSG:3332 (PL-1942 3° VIII)',
    def: '+proj=tmerc +lat_0=0 +lon_0=24 +k=1 +x_0=8500000 +y_0=0 +ellps=krass +units=m +no_defs +axis=neu',
  },
  'EPSG:25832': {
    srid: 25832,
    text: 'EPSG:25832 (ETRS89 / UTM zone 32N)',
    def: '+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs',
  },
  'EPSG:25833': {
    srid: 25833,
    text: 'EPSG:25833 (ETRS89 / UTM zone 33N)',
    def: '+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs',
  },
};

export {
  mapDataCoordinateSystem,
  mapDefaultZoom,
  mapDefaultMinZoom,
  mapDefaultMaxZoom,
  appName,
  contactData,
  defaultLanguage,
  additionalEpsgs,
  languages,
};
