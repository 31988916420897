import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

import config from '@/assets/styles/config';

Vue.use(Vuetify);

const icons = import.meta.glob('@/components/shared/icons/*.vue', { eager: true });
const iconComponents = Object.entries(icons).reduce((modules, [path, module]) => {
  modules[
    path
      .split(/[\\/]/)
      .pop()
      .replace(/\.[^.]+$/, '')
      .toLowerCase()
      .replace('icon', '')
  ] = {
    component: module.default,
  };
  return modules;
}, {});

export default new Vuetify({
  theme: {
    themes: {
      light: Object.entries(config.colors).reduce(
        (acc, [color, value]) => ({ ...acc, [color]: value.DEFAULT || value }),
        {}
      ),
    },
  },
  icons: {
    values: iconComponents,
  },
  lang: {
    current: 'pl',
  },
});
