<template>
  <v-row no-gutters class="fill-height" align="center">
    <v-col cols="12">
      <v-row no-gutters justify="center" class="mb-4">
        <v-col cols="auto">
          <img :src="`/ksse-logo_${currentLng}.svg`" width="200px" />
        </v-col>
      </v-row>
      <v-row no-gutters justify="center" align="center">
        <template v-if="migrationInProgress">
          <div class="tw-max-w-[60%] tw-mt-4 tw-flex tw-flex-col tw-justify-center tw-align-center tw-text-center">
            <p class="tw-font-semibold tw-text-2xl tw-text-primary">{{ $i18n.t('default.migrationTitle') }}</p>
            <p class="tw-text-sm">{{ $i18n.t('default.migrationSubtitle1') }}</p>
            <p class="tw-text-sm">{{ $i18n.t('default.migrationSubtitle2') }}</p>
          </div>
        </template>
        <template v-else>
          <v-col cols="auto" class="pr-5"> <MainLoader size="40" /> </v-col>
          <v-col cols="auto" class="text-body-2"> {{ $i18n.t('sidebar.waitForOffersLoading') }} </v-col>
        </template>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import MainLoader from '@/components/shared/MainLoader';

export default {
  components: { MainLoader },
  props: {
    migrationInProgress: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentLng() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style></style>
